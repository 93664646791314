.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.booked:hover {
  cursor: pointer;
}

/* Section - filter */
.sectionData_1_Filter .sectionData_1,
.sectionData_2_Filter .sectionData_2,
.sectionData_3_Filter .sectionData_3,
.sectionData_4_Filter .sectionData_4,
.sectionData_5_Filter .sectionData_5,
.sectionData_6_Filter .sectionData_6,
.sectionData_7_Filter .sectionData_7,
.sectionData_8_Filter .sectionData_8,
.sectionData_9_Filter .sectionData_9,
.sectionData_10_Filter .sectionData_10,
.sectionData_11_Filter .sectionData_11,
.sectionData_12_Filter .sectionData_12,
/* AREA - filter */
.areafilter_9 .area_9,
.areafilter_12 .area_12,
.areafilter_16 .area_16,
.areafilter_18 .area_18,
.areafilter_27 .area_27,
.areafilter_36 .area_36,
.areafilter_54 .area_54,
.areafilter_72 .area_72,
.areafilter_108 .area_108 {
  opacity: 1;
  /* animation: scaleUp 0.75s infinite forwards; */
  /* stroke: #a79c5e; */
  pointer-events: all;
}

/*
.areafilter_54 path {
  opacity: 0;
}
*/

.hide_txt {
  opacity: 0;
}
.show_txt {
  opacity: 1;
}

/* Section - filter */
.sectionData_1_Filter .sectionData_2,
.sectionData_1_Filter .sectionData_3,
.sectionData_1_Filter .sectionData_4,
.sectionData_1_Filter .sectionData_5,
.sectionData_1_Filter .sectionData_6,
.sectionData_1_Filter .sectionData_7,
.sectionData_1_Filter .sectionData_8,
.sectionData_1_Filter .sectionData_9,
.sectionData_1_Filter .sectionData_10,
.sectionData_1_Filter .sectionData_11,
.sectionData_1_Filter .sectionData_12,
.sectionData_1_Filter .sectionData_13,
.sectionData_1_Filter .sectionData_14,

/* Section - filter */
.sectionData_2_Filter .sectionData_1,
.sectionData_2_Filter .sectionData_3,
.sectionData_2_Filter .sectionData_4,
.sectionData_2_Filter .sectionData_5,
.sectionData_2_Filter .sectionData_6,
.sectionData_2_Filter .sectionData_7,
.sectionData_2_Filter .sectionData_8,
.sectionData_2_Filter .sectionData_9,
.sectionData_2_Filter .sectionData_10,
.sectionData_2_Filter .sectionData_11,
.sectionData_2_Filter .sectionData_12,
.sectionData_2_Filter .sectionData_13,
.sectionData_2_Filter .sectionData_14,


/* Section - filter */
.sectionData_3_Filter .sectionData_1,
.sectionData_3_Filter .sectionData_2,
.sectionData_3_Filter .sectionData_4,
.sectionData_3_Filter .sectionData_5,
.sectionData_3_Filter .sectionData_6,
.sectionData_3_Filter .sectionData_7,
.sectionData_3_Filter .sectionData_8,
.sectionData_3_Filter .sectionData_9,
.sectionData_3_Filter .sectionData_10,
.sectionData_3_Filter .sectionData_11,
.sectionData_3_Filter .sectionData_12,
.sectionData_3_Filter .sectionData_13,
.sectionData_3_Filter .sectionData_14,


/* Section - filter */
.sectionData_4_Filter .sectionData_1,
.sectionData_4_Filter .sectionData_2,
.sectionData_4_Filter .sectionData_3,
.sectionData_4_Filter .sectionData_5,
.sectionData_4_Filter .sectionData_6,
.sectionData_4_Filter .sectionData_7,
.sectionData_4_Filter .sectionData_8,
.sectionData_4_Filter .sectionData_9,
.sectionData_4_Filter .sectionData_10,
.sectionData_4_Filter .sectionData_11,
.sectionData_4_Filter .sectionData_12,
.sectionData_4_Filter .sectionData_13,
.sectionData_4_Filter .sectionData_14,

/* Section - filter */
.sectionData_5_Filter .sectionData_1,
.sectionData_5_Filter .sectionData_2,
.sectionData_5_Filter .sectionData_3,
.sectionData_5_Filter .sectionData_4,
.sectionData_5_Filter .sectionData_6,
.sectionData_5_Filter .sectionData_7,
.sectionData_5_Filter .sectionData_8,
.sectionData_5_Filter .sectionData_9,
.sectionData_5_Filter .sectionData_10,
.sectionData_5_Filter .sectionData_11,
.sectionData_5_Filter .sectionData_12,
.sectionData_5_Filter .sectionData_13,
.sectionData_5_Filter .sectionData_14,


/* Section - filter */
.sectionData_6_Filter .sectionData_1,
.sectionData_6_Filter .sectionData_2,
.sectionData_6_Filter .sectionData_3,
.sectionData_6_Filter .sectionData_4,
.sectionData_6_Filter .sectionData_5,
.sectionData_6_Filter .sectionData_7,
.sectionData_6_Filter .sectionData_8,
.sectionData_6_Filter .sectionData_9,
.sectionData_6_Filter .sectionData_10,
.sectionData_6_Filter .sectionData_11,
.sectionData_6_Filter .sectionData_12,
.sectionData_6_Filter .sectionData_13,
.sectionData_6_Filter .sectionData_14,

/* Section - filter */
.sectionData_7_Filter .sectionData_1,
.sectionData_7_Filter .sectionData_2,
.sectionData_7_Filter .sectionData_3,
.sectionData_7_Filter .sectionData_4,
.sectionData_7_Filter .sectionData_5,
.sectionData_7_Filter .sectionData_6,
.sectionData_7_Filter .sectionData_8,
.sectionData_7_Filter .sectionData_9,
.sectionData_7_Filter .sectionData_10,
.sectionData_7_Filter .sectionData_11,
.sectionData_7_Filter .sectionData_12,
.sectionData_7_Filter .sectionData_13,
.sectionData_7_Filter .sectionData_14,


/* Section - filter */
.sectionData_8_Filter .sectionData_1,
.sectionData_8_Filter .sectionData_2,
.sectionData_8_Filter .sectionData_3,
.sectionData_8_Filter .sectionData_4,
.sectionData_8_Filter .sectionData_5,
.sectionData_8_Filter .sectionData_6,
.sectionData_8_Filter .sectionData_7,
.sectionData_8_Filter .sectionData_9,
.sectionData_8_Filter .sectionData_10,
.sectionData_8_Filter .sectionData_11,
.sectionData_8_Filter .sectionData_12,
.sectionData_8_Filter .sectionData_13,
.sectionData_8_Filter .sectionData_14,

/* Section - filter */
.sectionData_9_Filter .sectionData_1,
.sectionData_9_Filter .sectionData_2,
.sectionData_9_Filter .sectionData_3,
.sectionData_9_Filter .sectionData_4,
.sectionData_9_Filter .sectionData_5,
.sectionData_9_Filter .sectionData_6,
.sectionData_9_Filter .sectionData_7,
.sectionData_9_Filter .sectionData_8,
.sectionData_9_Filter .sectionData_10,
.sectionData_9_Filter .sectionData_11,
.sectionData_9_Filter .sectionData_12,
.sectionData_9_Filter .sectionData_13,
.sectionData_9_Filter .sectionData_14,


/* Section - filter */
.sectionData_10_Filter .sectionData_1,
.sectionData_10_Filter .sectionData_2,
.sectionData_10_Filter .sectionData_3,
.sectionData_10_Filter .sectionData_4,
.sectionData_10_Filter .sectionData_5,
.sectionData_10_Filter .sectionData_6,
.sectionData_10_Filter .sectionData_7,
.sectionData_10_Filter .sectionData_8,
.sectionData_10_Filter .sectionData_9,
.sectionData_10_Filter .sectionData_11,
.sectionData_10_Filter .sectionData_12,
.sectionData_10_Filter .sectionData_13,
.sectionData_10_Filter .sectionData_14,

/* Section - filter */
.sectionData_11_Filter .sectionData_1,
.sectionData_11_Filter .sectionData_2,
.sectionData_11_Filter .sectionData_3,
.sectionData_11_Filter .sectionData_4,
.sectionData_11_Filter .sectionData_5,
.sectionData_11_Filter .sectionData_6,
.sectionData_11_Filter .sectionData_7,
.sectionData_11_Filter .sectionData_8,
.sectionData_11_Filter .sectionData_9,
.sectionData_11_Filter .sectionData_10,
.sectionData_11_Filter .sectionData_12,
.sectionData_11_Filter .sectionData_13,
.sectionData_11_Filter .sectionData_14,

/* Section - filter */
.sectionData_12_Filter .sectionData_1,
.sectionData_12_Filter .sectionData_2,
.sectionData_12_Filter .sectionData_3,
.sectionData_12_Filter .sectionData_4,
.sectionData_12_Filter .sectionData_5,
.sectionData_12_Filter .sectionData_6,
.sectionData_12_Filter .sectionData_7,
.sectionData_12_Filter .sectionData_8,
.sectionData_12_Filter .sectionData_9,
.sectionData_12_Filter .sectionData_10,
.sectionData_12_Filter .sectionData_11,
.sectionData_12_Filter .sectionData_13,
.sectionData_12_Filter .sectionData_14,


/* Section - filter */
.sectionData_13_Filter .sectionData_1,
.sectionData_13_Filter .sectionData_2,
.sectionData_13_Filter .sectionData_3,
.sectionData_13_Filter .sectionData_4,
.sectionData_13_Filter .sectionData_5,
.sectionData_13_Filter .sectionData_6,
.sectionData_13_Filter .sectionData_7,
.sectionData_13_Filter .sectionData_8,
.sectionData_13_Filter .sectionData_9,
.sectionData_13_Filter .sectionData_10,
.sectionData_13_Filter .sectionData_11,
.sectionData_13_Filter .sectionData_12,
.sectionData_13_Filter .sectionData_14,



/* Section - filter */
.sectionData_14_Filter .sectionData_1,
.sectionData_14_Filter .sectionData_2,
.sectionData_14_Filter .sectionData_3,
.sectionData_14_Filter .sectionData_4,
.sectionData_14_Filter .sectionData_5,
.sectionData_14_Filter .sectionData_6,
.sectionData_14_Filter .sectionData_7,
.sectionData_14_Filter .sectionData_8,
.sectionData_14_Filter .sectionData_9,
.sectionData_14_Filter .sectionData_10,
.sectionData_14_Filter .sectionData_11,
.sectionData_14_Filter .sectionData_12,
.sectionData_14_Filter .sectionData_13,


/* .areafilter9  */
.areafilter_9 .area_12,
.areafilter_9 .area_16,
.areafilter_9 .area_18,
.areafilter_9 .area_27,
.areafilter_9 .area_36,
.areafilter_9 .area_54,
.areafilter_9 .area_72,
.areafilter_9 .area_108,

/* .areafilter12  */
.areafilter_12 .area_9,
.areafilter_12 .area_18,
.areafilter_12 .area_16,
.areafilter_12 .area_27,
.areafilter_12 .area_36,
.areafilter_12 .area_54,
.areafilter_12 .area_72,
.areafilter_12 .area_108,

/* .areafilter16  */
.areafilter_16 .area_9,
.areafilter_16 .area_12,
.areafilter_16 .area_18,
.areafilter_16 .area_27,
.areafilter_16 .area_36,
.areafilter_16 .area_54,
.areafilter_16 .area_72,
.areafilter_16 .area_108,

/* .areafilter18  */
.areafilter_18 .area_9,
.areafilter_18 .area_12,
.areafilter_18 .area_16,
.areafilter_18 .area_27,
.areafilter_18 .area_36,
.areafilter_18 .area_54,
.areafilter_18 .area_72,
.areafilter_18 .area_108,

/* .areafilter27  */
.areafilter_27 .area_9,
.areafilter_27 .area_12,
.areafilter_27 .area_18,
.areafilter_27 .area_16,
.areafilter_27 .area_36,
.areafilter_27 .area_54,
.areafilter_27 .area_72,
.areafilter_27 .area_108,

/* .areafilter36  */
.areafilter_36 .area_9,
.areafilter_36 .area_12,
.areafilter_36 .area_18,
.areafilter_36 .area_16,
.areafilter_36 .area_27,
.areafilter_36 .area_54,
.areafilter_36 .area_72,
.areafilter_36 .area_108,

/* .areafilter54  */
.areafilter_54 .area_9,
.areafilter_54 .area_12,
.areafilter_54 .area_18,
.areafilter_54 .area_16,
.areafilter_54 .area_27,
.areafilter_54 .area_36,
.areafilter_54 .area_72,
.areafilter_54 .area_108,

/* .areafilter_72  */
.areafilter_72 .area_9,
.areafilter_72 .area_12,
.areafilter_72 .area_16,
.areafilter_72 .area_18,
.areafilter_72 .area_27,
.areafilter_72 .area_36,
.areafilter_72 .area_54,
.areafilter_72 .area_108,

/* .areafilter_108  */
.areafilter_108 .area_9,
.areafilter_108 .area_12,
.areafilter_108 .area_18,
.areafilter_108 .area_16,
.areafilter_108 .area_27,
.areafilter_108 .area_36,
.areafilter_108 .area_54, 
.areafilter_108 .area_72 {
  opacity: 0;
  cursor: default;
  /* pointer-events: none; */
}

@keyframes scaleUp {
  0%,
  100% {
    fill-opacity: 0.7;
  }
  50% {
    fill-opacity: 1;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
