.container {
  /* font-size: 2rem; */
  position: absolute;
  bottom: 1%;
  right: 1%;
}

.formInputContainer {
  font-size: 1.25rem;
  margin-bottom: 0.85rem;
}

.formInputContainer label {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.formInputContainer input,
select {
  padding: 1rem 0.75rem;
  border: 0;
  outline: none;
  border: 0.2rem solid #a79c5e;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  font-size: 1.5rem;
}
.formInputContainer select {
  display: block;
}
.button {
  background: #a79c5e;
  border: #a79c5e solid 0.2rem;
  color: #fff;
  border-radius: 0.75rem;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  transition: 0.25s;
  cursor: pointer;
  position: relative;
}

.button img {
  max-height: 2rem;
}
